import React from 'react';
import { FormControlProps } from '@formium/react';

const StyledFormControl = React.memo(
  ({ children, description, error, label, labelFor }: FormControlProps) => {
    return (
      <div>
        {label && <label htmlFor={labelFor}>{label}</label>}
        {description && <div>{description}</div>}
        {children}
        {error && <div style={{ color: 'red' }}>{error}</div>}
      </div>
    );
  }
);

export default StyledFormControl;
