import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/styles';
import React, { useMemo } from 'react';
import { ThemeProvider } from 'styled-components';
import { createTheme } from 'styles/theme';

interface Props {
  children: React.ReactNode;
}

const CustomThemeProvider = ({ children }: Props) => {
  // Here is where you can switch the theme dynamically, whether its
  // changing a single colour, or switching from a light theme to a dark theme.
  // NOTE: This is memoized since creating the theme should only be done when
  // necessary.
  const customTheme = useMemo(() => createTheme(), []);

  return (
    <MuiThemeProvider theme={customTheme}>
      <ThemeProvider theme={customTheme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </MuiThemeProvider>
  );
};

export default CustomThemeProvider;
