import React from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { useLocation } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';

const PickupConfirmForm = () => {
  // this should use react router and the params but i'm gonna leave it like this for a quick and dirty demo
  const location = useLocation();
  const path = location.pathname;
  const pathArray = path.split('/');
  const token = pathArray[2];
  const tokenSplit = token.split('-');
  const confirm = tokenSplit[0];

  return (
    <Grid container justify="center">
      <Grid item xs={12} container justify="center">
        <Grid item xs={8}>
          <Box mt={3} mb={3}>
            <Typography variant="h4" gutterBottom>
              Thank you!
            </Typography>
            <Box mt={3} mb={3}>
              <Typography variant="subtitle1">
                An Associate will be right with you.
              </Typography>
              <Typography variant="subtitle1">
                Please remain in the designated pickup area.
              </Typography>
              <Typography variant="subtitle1">
                This is your confirmation number: {confirm.toUpperCase()}
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PickupConfirmForm;
