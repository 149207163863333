import { queryClientConfig } from 'api/react-query/config';
import React from 'react';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

interface Props {
  children: React.ReactNode;
}

const CustomQueryClientProvider = ({ children }: Props) => {
  return (
    <QueryClientProvider client={queryClientConfig}>
      {/* The devtools can be optionally enabled if needed */}
      {process.env.REACT_APP_ENABLE_REACT_QUERY_DEVTOOLS && (
        <ReactQueryDevtools initialIsOpen={false} />
      )}
      {children}
    </QueryClientProvider>
  );
};

export default CustomQueryClientProvider;
