import { put, takeEvery, delay } from 'redux-saga/effects';

// redux-sagas counter example
export function* incrementAsync() {
  yield delay(1000);
  yield put({ type: 'INCREMENT' });
}

function* rootSaga() {
  // redux-sagas counter example
  yield takeEvery('INCREMENT_ASYNC', incrementAsync);

  // Sagas for the app here
  // yield all([
  //
  // ]);
}

export default rootSaga;
