import React from 'react';
import styled from 'styled-components';
import { Radio, FormControlLabel } from '@material-ui/core';
import { Field } from 'formik';
import { makeStyles } from '@material-ui/core/styles';

export const StyledRadioLabel = styled(FormControlLabel)`
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 8px;
  margin-bottom: 8px;
  width: 100%;
  height: 52px;
  justify-content: center;
  text-transform: uppercase;
`;

const useStyles = makeStyles({
  checked: {
    border: '1px solid rgb(51,51,51)',
    backgroundColor: '#222',
    color: '#fff',
  },
  root: {
    border: '1px solid rgb(118,118,118)',
    backgroundColor: '#fff',
    color: ' rgb(51,51,51)',
  },
  focused: {},
  notchedOutline: {},
});

export const StyledRadio = React.memo(({ ...props }: any) => {
  const classes = useStyles();
  return (
    <Field
      name={props.name}
      value={props.value}
      id={props.id}
      label={props.value}
    >
      {({
        field, // this gives you access to formik field props like { name, value, onChange, onBlur }
        form, // this gies you acess to formik form props like  {values, setXXXX, handleXXXX, dirty, isValid, status, etc.}
      }: // meta,
      any) => {
        const { name, onChange } = field;
        const { dirty } = form;

        const formValues = form.values;

        return (
          <StyledRadioLabel
            className={
              props.value === formValues[name] && dirty
                ? classes.checked
                : classes.root
            }
            key={props.id}
            value={props.value}
            control={
              <Radio
                checked={props.value === formValues[name]}
                onBlur={field.onBlur}
                disabled={field.disabled}
                onChange={onChange}
                value={props.value}
                name={props.name}
                id={props.id}
                style={{ padding: 0 }}
                checkedIcon={<div />}
                icon={<div />}
              />
            }
            label={props.value}
          />
        );
      }}
    </Field>
  );
});
