import { QueryClient } from 'react-query';

// For query keys
export enum APIQueryKey {
  GetMe = 'GetMe',
  GetUsers = 'GetUsers',
  GetUserById = 'GetUserById',
}

export const queryClientConfig = new QueryClient({
  defaultOptions: {
    // Queries defaults
    // https://react-query.tanstack.com/reference/useQuery
    queries: {
      // Don't retry failed queries by default
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
});
