import React from 'react';
import { Button, Grid } from '@material-ui/core';

const SubmitButton = () => {
  return (
    <Grid
      item
      xs={12}
      style={{ display: 'flex', justifyContent: 'flex-end', paddingBottom: 40 }}
      container
    >
      <Grid item xs={6} sm={4}>
        <Button
          fullWidth
          type="submit"
          style={{
            borderRadius: 0,
            textTransform: 'uppercase',
            color: '#fff',
            backgroundColor: '#222',
            fontWeight: 400,
            padding: '12px 30px',
            fontSize: 15,
          }}
        >
          Submit
        </Button>
      </Grid>
    </Grid>
  );
};

export default SubmitButton;
