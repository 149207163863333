import { applyMiddleware, createStore, compose, CombinedState } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import { History } from 'history';

import rootSaga from 'redux-app/rootSaga';
import createRootReducer from 'redux-app/rootReducer';
import type { State } from 'redux-app/types';

export default function configureStore(
  initialState: CombinedState<State> | undefined,
  history: History
) {
  const sagaMiddleware = createSagaMiddleware();
  const reduxRouterMiddleware = routerMiddleware(history);

  const middlewares = [reduxRouterMiddleware, sagaMiddleware];

  const composeEnhancers =
    (process.env.NODE_ENV !== 'production' &&
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
    compose;

  const store = createStore(
    createRootReducer(history),
    initialState,
    composeEnhancers(applyMiddleware(...middlewares))
  );

  sagaMiddleware.run(rootSaga);

  if (process.env.NODE_ENV !== 'production' && module.hot) {
    module.hot.accept('redux-app/rootReducer', () => {
      const nextRootReducer = require('redux-app/rootReducer'); // eslint-disable-line global-require

      store.replaceReducer(nextRootReducer);
    });
  }

  return store;
}
