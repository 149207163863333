import StylesProvider from '@material-ui/styles/StylesProvider';
import CustomThemeProvider from 'components/CustomThemeProvider';
import { ConnectedRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Route } from 'react-router-dom';
import CustomQueryClientProvider from 'api/react-query/CustomQueryClientProvider';

import './index.css';
import App from './App';
import configureStore from './store/configureStore';
import * as serviceWorker from './serviceWorker';

const initialState = undefined;
const history = createBrowserHistory();
const store = configureStore(initialState, history);

ReactDOM.render(
  <Provider store={store}>
    <CustomQueryClientProvider>
      <StylesProvider injectFirst>
        <CustomThemeProvider>
          <ConnectedRouter history={history}>
            {/*
              Rendering the App as a Route is REQUIRED. Otherwise, routes defined in
              App, or components within App will not update when clicked on (only on
              refreshes).
              https://stackoverflow.com/a/44356956
            */}
            <Route component={App} />
          </ConnectedRouter>
        </CustomThemeProvider>
      </StylesProvider>
    </CustomQueryClientProvider>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
