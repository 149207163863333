// src/reducers/index.js
import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { History } from 'history';

// import entities from './rootEntities';

interface CounterAction {
  type: 'INCREMENT' | 'INCREMENT_IF_ODD' | 'INCREMENT_ASYNC' | 'DECREMENT';
}

// redux-saga counter example
function counter(state = 0, action: CounterAction) {
  switch (action.type) {
    case 'INCREMENT':
      return state + 1;
    case 'INCREMENT_IF_ODD':
      return state % 2 !== 0 ? state + 1 : state;
    case 'DECREMENT':
      return state - 1;
    default:
      return state;
  }
}

export default (history: History) =>
  combineReducers({
    router: connectRouter(history),

    // redux-sagas example
    counter,

    // redux + normalizr example
    // entities,
  });
