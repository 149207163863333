import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import RoomIcon from '@material-ui/icons/Room';
import Toolbar from '@material-ui/core/Toolbar';
import LandingPage from './LandingPage';
import PickupForm from './PickupFormPage';
// import SchedulePage from './SchedulePage';
import PickupConfirmPage from './PickupConfirmPage';
import Logo from '../assets/icons/logo.svg';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexFlow: 'column',
    height: '100vh',
  },
}));

const confirm = `/pickup-confirm/([0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12})`;

const Pages = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <AppBar
        position="static"
        color="transparent"
        elevation={0}
        style={{ borderBottom: '1px solid black' }}
      >
        <Toolbar>
          <Box ml={1} mt={2} mb={0} display="flex">
            <Box>
              <img
                src={Logo}
                alt="logo"
                style={{ maxHeight: '80%', maxWidth: '80%' }}
              />
            </Box>
            <Box mt={1}>
              <RoomIcon />
            </Box>
            <Box style={{ marginTop: 10 }}>
              <Typography>Indigo — Bay and Bloor</Typography>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>

      <Switch>
        <Route path="/" exact component={LandingPage} />
        <Route path="/instore" component={PickupForm} />
        <Route path="/curbside" component={PickupForm} />
        <Route path={confirm} component={PickupConfirmPage} />
      </Switch>
    </div>
  );
};

export default Pages;
