import React from 'react';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  outlinedRoot: {
    backgroundColor: 'rgb(255,255,255)',
    border: '1px solid rgb(118,118,118)',
    borderRadius: 0,
    '&$focused $notchedOutline': {
      border: 'none',
    },
    '&$invalid $notchedOutline': {
      border: '1px solid rgb(253,32,38)',
    },
  },
  error: {
    backgroundColor: 'rgb(255,255,255)',
    border: '1px solid rgb(253,32,38)',
  },
  nakedRoot: {
    backgroundColor: '#222',
  },
  // override mui borders
  focused: {},
  disabled: { color: '#0000' },
  notchedOutline: { border: 'none' },
  // hide the updownarrows on number input
  outlinedInput: {
    '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
  nakedInput: {
    color: 'rgb(51,51,51)',
    '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input:invalid + fieldset': {
      borderColor: 'red',
      borderWidth: 2,
    },
    '&$disabled': {
      color: 'rgb(51,51,51)',
    },
  },
});

const StyledTextField = (props: TextFieldProps) => {
  const { variant } = props;

  const classes = useStyles();
  // https://github.com/mui-org/material-ui/issues/15697
  // mui typesript error for handling conditional variants. this is the work around
  const tsProps = (() => {
    let tsVariant;
    let InputProps;
    switch (variant) {
      case 'outlined': {
        tsVariant = { variant: variant as 'outlined' };
        InputProps = {
          InputProps: {
            classes: {
              root: classes.outlinedRoot,
              focused: classes.focused,
              notchedOutline: classes.notchedOutline,
              input: classes.outlinedInput,
            },
          },
        };
        break;
      }
      case 'filled': {
        tsVariant = { variant: 'filled' as 'filled' };
        break;
      }
      case 'standard': {
        tsVariant = { variant: 'standard' as 'standard' };
        InputProps = {
          InputProps: {
            disableUnderline: true,
            classes: {
              root: classes.nakedRoot,
              focused: classes.focused,
              disabled: classes.disabled,
              input: classes.nakedInput,
              error: classes.error,
            },
          },
        };
        break;
      }
      default: {
        break;
      }
    }
    return { ...props, ...tsVariant, ...InputProps };
  })();
  return <TextField fullWidth {...tsProps} />;
};

const IndigoTextField = React.memo(({ ...props }: TextFieldProps) => {
  const { type } = props;

  switch (type) {
    default:
      return (
        <StyledTextField
          {...props}
          // style={{ paddingTop: '8px' }}
          variant="outlined"
        />
      );
  }
});

export default IndigoTextField;
