import React from 'react';
import { Box, BoxProps, Grid } from '@material-ui/core';

const StyledFieldWrapper = ({ children }: BoxProps) => {
  return (
    <Grid item xs={12}>
      <Box mb={1} style={{ minHeight: '100px' }}>
        {children}
      </Box>
    </Grid>
  );
};

export default StyledFieldWrapper;
