import React from 'react';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useMediaQuery, useTheme } from '@material-ui/core';
import Image from 'material-ui-image';

const PickupForm = () => {
  const media = useTheme();
  const isMobile = useMediaQuery(media.breakpoints.down('sm'));
  return (
    <Grid container justify="center">
      <Grid item xs={12} container justify="center">
        <Grid item xs={11}>
          <Box mt={3} mb={3}>
            <Typography
              align="center"
              variant="subtitle1"
              style={{ fontSize: 18 }}
            >
              Thank you for your order!
            </Typography>
            <Typography
              variant="subtitle2"
              align="center"
              style={{
                fontWeight: 600,
                letterSpacing: '2.03px',
                textTransform: 'uppercase',
                fontSize: 25,
                marginTop: 4,
              }}
            >
              Order #154345
            </Typography>
          </Box>
          <Box>
            <Box>
              <Image
                aspectRatio={335 / 509}
                src="https://ucarecdn.com/86e67769-9f81-4cae-ba88-98929dd3353c/-/preview/360x"
                alt="book cover"
              />
              <br />
              <Typography
                variant="h4"
                style={{
                  textTransform: 'uppercase',
                  fontSize: 25,
                  marginBottom: 10,
                }}
              >
                A Promised Land
              </Typography>
              <Typography variant="subtitle2">
                by{' '}
                <strong style={{ textTransform: 'uppercase' }}>
                  Barack Obama
                </strong>
              </Typography>
            </Box>
            <Box mt={3} mb={3}>
              <Typography variant="subtitle1">
                If you&apos;ve already scheduled your pickup time and are on
                your way let our team know.
              </Typography>
              <Typography variant="subtitle1">
                Schedule a time to pick up your order below.
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={8} container>
          <Grid item xs={12}>
            <Box mb={3} mt={1}>
              <Button
                fullWidth
                href="/curbside"
                style={{
                  backgroundColor: '#222',
                  color: 'white',
                  height: 44,
                  maxWidth: isMobile ? 300 : 150,
                  borderRadius: 0,
                }}
              >
                <Typography
                  variant="button"
                  style={{ fontWeight: 600, letterSpacing: 2 }}
                >
                  In-store
                </Typography>
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box mb={3} mt={1}>
              <Button
                fullWidth
                href="/curbside"
                style={{
                  backgroundColor: '#222',
                  color: 'white',
                  height: 44,
                  maxWidth: isMobile ? 300 : 150,
                  borderRadius: 0,
                }}
              >
                <Typography
                  variant="button"
                  style={{ fontWeight: 600, letterSpacing: 2 }}
                >
                  Store Entrance
                </Typography>
              </Button>
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box mb={5} mt={1}>
              <Button
                fullWidth
                href="/schedule-form"
                style={{
                  backgroundColor: '#222',
                  color: 'white',
                  height: 44,
                  maxWidth: isMobile ? 300 : 150,
                  borderRadius: 0,
                }}
              >
                <Typography
                  variant="button"
                  style={{ fontWeight: 600, letterSpacing: 2 }}
                >
                  Curbside
                </Typography>
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PickupForm;
