import { createMuiTheme, ThemeOptions } from '@material-ui/core/styles';

export interface CustomTheme {
  // Place custom theme properties here
  sampleColor: string;
}
export interface CustomThemeOptions {
  // Place custom theme properties here, but make them optional (seems to be
  // what Material UI does)
  sampleColor?: string;
}

// Material-ui theme
// https://material-ui.com/customization/default-theme/#default-theme
export const createTheme = (themeOverrides?: Partial<ThemeOptions>) =>
  createMuiTheme(
    {
      palette: {
        primary: {
          main: '#3f51b5',
        },
        secondary: {
          main: '#f50057',
        },
        error: {
          main: '#f44336',
        },
        background: {
          default: '#fafafa',
        },
      },
      // we'll need the font file
      typography: {
        fontFamily: [
          '"Gibson Regular"',
          'Helvetica',
          'Arial',
          'Verdana',
          'sans-serif',
        ].join(','),
      },
      button: {
        typography: {
          fontFamily: [
            '"Gibson SemiBold"',
            'Helvetica',
            'Arial',
            'Verdana',
            'sans-serif',
          ].join(','),
        },
      },
      custom: {
        sampleColor: '#abcabc',
      },
    } as ThemeOptions,
    themeOverrides as object
  );
