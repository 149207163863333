import { formium } from 'components/Formium/lib';
import { Form } from '@formium/types';

export async function getStaticProps(slug: string) {
  const form = await formium.getFormBySlug(slug);
  return { props: { form } };
}

export async function getSubmission(submissionId: string) {
  let errors;
  const form = await formium
    .getSubmit(submissionId)
    .catch((error: any) => (errors = error));
  return { props: { form }, errors };
}

export function mergeSubmission(submissionData: any, form: Form) {
  let data = form;
  const formFields = form.schema?.fields || {};
  const keys = Object.keys(formFields);

  keys.map((item: any) => {
    return data.schema?.fields[item] && submissionData
      ? (data.schema.fields[item].defaultValue =
          submissionData[data.schema.fields[item].slug])
      : undefined;
  });
  return data;
}
