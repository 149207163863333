import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useLocation } from 'react-router-dom';
import StyledFormControl from 'components/Formium/FormControl';
import { useHistory, matchPath } from 'react-router';
import StyledFieldWrapper from 'components/Formium/FieldWrapper';
import { formium } from 'components/Formium/lib';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import SubmitButton from 'components/Formium/SubmitButton';
import IndigoTextField from 'components/FormiumFields/IndigoTextField';
import { StyledRadio } from 'components/FormiumFields/RadioButtons';
import {
  FormiumForm,
  defaultComponents,
} from 'components/Formium/formium-master/packages/react/src/FormiumForm';
import {
  getStaticProps,
  mergeSubmission,
} from 'components/Formium/FormiumHelpers';

const PickupForm = () => {
  const location = useLocation();
  const isInstore = matchPath(location.pathname, {
    path: '/instore',
    exact: true,
    strict: false,
  });

  const history = useHistory();
  const [formData, setFormData] = useState<any>();
  const [token, setToken] = useState<String>();
  const formSlug = isInstore ? 'ind-instore-pickup' : 'ind-pick-up-form';
  const form = getStaticProps(formSlug);
  const user = {
    firstName: 'John',
    lastName: 'Smith',
    email: 'john.smith@hello.com',
    orderNumber: 'OR89080',
  };
  useEffect(() => {
    form.then((resp) => {
      const uuid = uuidv4();
      setFormData(
        mergeSubmission({ ...user, submittoken: uuid }, resp.props.form)
      );
      setToken(uuid);
    });
  }, []);
  const nextPage = `/pickup-confirm/${token}`;

  const handleSubmit = async (values: any) => {
    await formium
      .submitForm(formSlug, values)
      .then((response: any) =>
        response.ok
          ? history.push(nextPage)
          : alert('submit error, please try again')
      );
  };

  // need to add custom radio buttons

  const Header = () => {
    return (
      <>
        <h1>Order Details</h1>
        <Box mb={2}>
          <p>Please provide your order details below.</p>
        </Box>
      </>
    );
  };

  const components = {
    ...defaultComponents,
    SubmitButton,
    TextInput: IndigoTextField,
    FieldWrapper: StyledFieldWrapper,
    Radio: StyledRadio,
    FormControl: StyledFormControl,
    Header,
  };

  return (
    <Paper
      style={{
        padding: '0px 20px 0px 20px',
        height: '100%',
        overflow: 'scroll',
      }}
    >
      {formData && (
        <FormiumForm
          data={formData}
          components={components}
          onSubmit={(values) => handleSubmit(values)}
        />
      )}
    </Paper>
  );
};

export default PickupForm;
